@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Eudoxus Sans", sans-serif;
  scroll-behavior: smooth;
}
body{
overflow-x: hidden;
}
.bg1{
  background-image: url('./assets/hero-bg.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: -10;
  background-size: cover;

}
.box{
  box-shadow  : 1px 3px 10px 5px #9444CB;
}